.exp-content-div {
  margin-top: 2%;
  display: inline-block;
  width: 90%;
  padding: 3%;
  padding-top: 0%;
  padding-bottom: 0%;
  vertical-align: top;
  text-align: center;
}

.exp-content-div h1 {
  font-size: 28px;
  margin-top: 20px;
  text-align: center;
}

.exp-content-div h2 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.exp-content-div p {
  text-indent: 0px;
  margin-top: 10px;
}
.home-content-div {
  margin-top: 2%;
  display: inline-block;
  width: 26%;
  padding: 3%;
  padding-top: 0%;
  vertical-align: top;
  text-align: center;
}

.home-content-div h1 {
  font-size: 24px;
  padding-bottom: 1%;
  text-align: center;
}

.home-content-div p {
  text-indent: 0px;
}

.home-content-div ul {
  list-style: none;
  padding-left: 2%;
  font-size: 0.8em;
}

@media (max-width: 600px) {
  .desc-div {
    font-size: 16px;
    padding-left: 2%;
    padding-right: 2%;
  }

  .home-content-div {
    width: 90%;
  }

}
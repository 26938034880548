.tab-container {
  font-size: 18px;
  display: inline-block;
  padding: 20px;
  margin: 5px;
  transition: padding 0.5s;

  background-image:
    linear-gradient(135deg, #eeddff80, #ccbbee80 80%),
    linear-gradient(180deg, #eeddff80, #ccbbee80 80%);
}

.tab-container:hover {
  padding: 30px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .tab-container {
    font-size: 18px;
    padding: 12px;
    margin: 2px;
  }

  .tab-container:hover {
    padding: 10px;
  }

}
.cw-content-div {
  margin-top: 2%;
  display: inline-block;
  width: 26%;
  padding: 3%;
  padding-top: 0%;
  padding-bottom: 0%;
  vertical-align: top;
  text-align: center;
}

.cw-content-div h1 {
  font-size: 24px;
  margin-top: 20px;
  text-align: center;
}

.cw-content-div h2 {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.cw-content-div p {
  text-indent: 0px;
  margin-top: 10px;
}

.mobile {
  display: none;
}

@media (max-width: 600px) {
  .desc-div {
    font-size: 16px;
    padding-left: 2%;
    padding-right: 2%;
  }

  .cw-content-div {
    width: 90%;
    display: none;
  }

  .mobile {
    display: inline-block;
  }

}